import React from 'react';

import { IconButton } from '@chakra-ui/react';
import { LeftArrow, RightArrow } from '@styled-icons/boxicons-regular';
import { ArrowProps } from 'react-multi-carousel';

interface Props extends ArrowProps {
  type: 'prev' | 'next';
}

const CustomArrow: React.FC<Props> = (props) => {
  const { type, onClick } = props;
  return (
    <IconButton
      position="absolute"
      left={type === 'prev' ? '-60px' : 'unset'}
      onClick={onClick}
      right={type === 'next' ? '-60px' : 'unset'}
      top="50%"
      transform="translateY(-50%)"
      aria-label={type === 'prev' ? 'Previous' : 'Next'}
      borderRadius="full"
      variant="outlineIcon"
    >
      {type === 'prev' ? <LeftArrow size="24px" /> : <RightArrow size="24px" />}
    </IconButton>
  );
};

export default CustomArrow;
