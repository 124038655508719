import { ChangeEvent, useState } from 'react';
import { KeyboardEvent } from 'react';

import { Input, InputGroup, InputGroupProps, InputLeftElement } from '@chakra-ui/react';
import { Search } from '@styled-icons/boxicons-regular';

import styles from '@styles/export.module.scss';

interface Props extends Omit<InputGroupProps, 'onChange'> {
  borderRadius?: string;
  onEnter?: (text: string) => void;
  onChange?: (text: string) => void;
}

const SearchBar: React.FC<Props> = (props) => {
  const [text, setText] = useState('');
  const { borderRadius, onEnter, onChange, ...inputGroupProps } = props;

  const handleKeyDown = (e: KeyboardEvent<HTMLInputElement>) => {
    if (e.key == 'Enter' && onEnter) {
      onEnter(text);
    }
  };

  const handleChange = (e: ChangeEvent<HTMLInputElement>) => {
    setText(e.currentTarget.value);
    onChange?.(e.currentTarget.value);
  };

  return (
    <InputGroup as="section" {...inputGroupProps}>
      <InputLeftElement height="60px" width="56px" borderRadius="full">
        <Search size="24px" />
      </InputLeftElement>
      <Input
        type="search"
        background={styles.colorPrimaryWhite}
        placeholder="Bạn muốn đi đâu nè?"
        borderRadius={borderRadius}
        height="60px"
        fontSize="18px"
        onChange={handleChange}
        onKeyDown={handleKeyDown}
      />
    </InputGroup>
  );
};

export default SearchBar;
