import { Box, Fade } from '@chakra-ui/react';
import Carousel, { ButtonGroupProps } from 'react-multi-carousel';

import useBreakPoints from '@hooks/use-break-points';

import TripCard from '@components/common/TripCard';
import { breakpoints } from '@constants/index';
import { Trip } from '@types';

import CustomArrow from './CustomArrow';

interface Props {
  title: string;
  trips: Trip[];
}

const TripSection: React.FC<Props> = (props) => {
  const { title, trips } = props;
  const { isDesktop } = useBreakPoints();
  return (
    <Box as="section" py="8" position="relative">
      <Box as="h1" mb="2">
        {title}
      </Box>

      <Carousel
        ssr
        partialVisible
        arrows={false}
        swipeable
        responsive={carousolResponsive}
        customButtonGroup={isDesktop ? <CarousolButtonGroup /> : null}
        renderButtonGroupOutside
      >
        {trips.map((trip) => (
          <Box key={trip.id} p="3">
            <TripCard trip={trip} />
          </Box>
        ))}
      </Carousel>
    </Box>
  );
};

const CarousolButtonGroup: React.FC<ButtonGroupProps> = ({ next, previous, ...rest }) => {
  const { carouselState } = rest;
  const { currentSlide = 0, slidesToShow = 0, totalItems = 0 } = carouselState ?? {};
  const isPrevDisabled = currentSlide === 0;
  const isNextDisabled = currentSlide + slidesToShow >= totalItems;
  return (
    <>
      <Fade in={!isPrevDisabled} unmountOnExit>
        <CustomArrow type="prev" onClick={previous} />
      </Fade>
      <Fade in={!isNextDisabled} unmountOnExit>
        <CustomArrow type="next" onClick={next} />
      </Fade>
    </>
  );
};

const carousolResponsive = {
  desktop: {
    breakpoint: { max: 5000, min: breakpoints.tablet },
    items: 3,
    partialVisibilityGutter: 72,
  },
  tablet: {
    breakpoint: { max: breakpoints.tablet, min: breakpoints.mobile },
    items: 3,
    partialVisibilityGutter: 56,
  },
  mobile: {
    breakpoint: { max: breakpoints.mobile, min: 0 },
    items: 1,
    partialVisibilityGutter: 96,
  },
};

export default TripSection;
