import { Box } from '@chakra-ui/react';

import ShimmerImage from '@components/common/ShimmerImage';
import { Collection } from '@types';

import styles from '@styles/export.module.scss';

interface PropsWithChildren {
  collection: Collection;
}

const CollectionCard: React.FC<PropsWithChildren> = (props) => {
  const { collection } = props;
  return (
    <Box
      as="article"
      flex="1"
      minWidth="240px"
      height={{
        base: '365px',
        sm: '280px',
        md: '365px',
      }}
      backgroundRepeat="no-repeat"
      backgroundSize="cover"
      cursor="pointer"
      display="flex"
      flexDirection="column"
      justifyContent="flex-end"
      color={styles.colorPrimaryWhite}
      p="20px 32px"
      position="relative"
    >
      <Box as="h1">{collection.name}</Box>
      <Box as="p">{collection.description}</Box>
      <ShimmerImage
        src={collection.thumbnail}
        position="absolute"
        top="0"
        left="0"
        width="100%"
        height="100%"
        zIndex="-1"
        borderRadius="10px"
        bgGradient="linear(to-b, transparent, black)"
        alt={`Collection ${collection.name} Background`}
      />
    </Box>
  );
};

export default CollectionCard;
