import { Box, Container } from '@chakra-ui/react';

import ShimmerImage from '@components/common/ShimmerImage';
import SearchBar from '@components/modules/search/SearchBar';

import styles from '@styles/export.module.scss';

interface Props {
  backgroundUrl: string;
  onEnter?: (text: string) => void;
}

const SearchHeader: React.FC<Props> = (props) => {
  return (
    <Box as="header" mt="20" position="relative">
      <ShimmerImage
        src={props.backgroundUrl}
        position="absolute"
        top="0"
        left="0"
        width="100%"
        height="100%"
        zIndex="-1"
        bgGradient="linear(to-b, transparent, black)"
        alt="Search Header Background"
        priority
      />
      <Container
        maxWidth="5xl"
        my="14"
        display="flex"
        flexDirection="column"
        justifyContent="flex-end"
        alignItems="center"
        height={{
          base: '250px',
          sm: '250px',
          md: '300px',
        }}
      >
        <Box as="h1" color={styles.colorPrimaryWhite} fontSize="64px">
          Đi chơi và chia sẻ
        </Box>
        <Box as="p" color={styles.colorPrimaryWhite} mt="5" mb="12">
          Chia sẻ khoảnh khắc, chia sẻ chuyến đi
        </Box>
        <SearchBar borderRadius="full" onEnter={props.onEnter} />
      </Container>
    </Box>
  );
};

export default SearchHeader;
