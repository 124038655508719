import dynamic from 'next/dynamic';
import Head from 'next/head';
import { useRouter } from 'next/router';

import { Stack } from '@chakra-ui/react';
import { signInAnonymously } from 'firebase/auth';
import type { NextPage } from 'next';

import { CollectionCard, SearchHeader } from '@components/modules/home';
import { DefaultLayout } from '@layouts/index';
import { Collection, Trip } from '@types';
import TripService from 'app/api/TripService';
import { firebaseAuth } from 'app/firebase/firebase';
import { mockCollections } from 'mock/data';

const TripSection = dynamic(() => import('@components/modules/home/TripSection'));

interface PageProps {
  backgroundUrl: string;
  collections: Collection[];
  popularTrips: Trip[];
  bestOfTheMonthTrips: Trip[];
}

const Home: NextPage<PageProps> = ({ backgroundUrl, collections, popularTrips, bestOfTheMonthTrips }) => {
  const router = useRouter();

  const handleSearch = (text: string) => {
    if (text) {
      router.push({
        pathname: '/search',
        query: { q: text },
      });
    }
  };

  return (
    <>
      <Head>
        <title>Sherdtrip</title>
        <meta name="description" content="No need to plan your trip" />
        <link rel="icon" href="/favicon.ico" />
      </Head>
      <SearchHeader backgroundUrl={backgroundUrl} onEnter={handleSearch} />
      <DefaultLayout overflowX="visible" pt="16">
        <Stack as="section" spacing="4" direction="row" pb="8" overflow="hidden">
          {collections.map((collection) => (
            <CollectionCard key={collection.id} collection={collection} />
          ))}
        </Stack>
        <TripSection title="Phổ biến" trips={popularTrips} />
        {/* <TripSection title="Gần bạn" trips={mockTrips2} /> */}
        <TripSection title="Đẹp nhất tháng này" trips={bestOfTheMonthTrips} />
      </DefaultLayout>
    </>
  );
};

export const getStaticProps = async () => {
  const backgroundUrl =
    'https://firebasestorage.googleapis.com/v0/b/sherdtrip.appspot.com/o/default-bg.png?alt=media&token=b885b00b-9dfa-460c-8d45-2a313f01f8cc';
  if (!firebaseAuth.currentUser) {
    await signInAnonymously(firebaseAuth);
  }
  let popularTrips = await TripService.getPopulars();
  popularTrips = JSON.parse(JSON.stringify(popularTrips));

  let bestOfTheMonthTrips = await TripService.getBestOfTheMonth();
  bestOfTheMonthTrips = JSON.parse(JSON.stringify(bestOfTheMonthTrips));
  const collections: Collection[] = mockCollections;
  return {
    props: {
      backgroundUrl,
      collections,
      popularTrips,
      bestOfTheMonthTrips,
    },
    revalidate: 2400, // 40 minutes
  };
};

export default Home;
